import * as api from '../api/'

export const getProject = (token, companyId) => {
	return dispatch => {
		dispatch({ type: 'project/wait'});
		api.getProject(token, companyId)
		.then((resp) => {
			dispatch({type: 'project/list', payload: resp.data});
		})
		.catch(e => console.log(e));
	}
}

export const postProject = (token, project) => {
	return dispatch => {
		dispatch({ type: 'project/wait'});
		api.postProject(token, project)
		.then(() => {
			const companyId = project.companyId
			api.getProject(token, companyId)
			.then((resp) => {
				dispatch({type: 'project/list', payload: resp.data});
				dispatch({ type: 'task/wait_update'});
				api.getTask(token)
				.then((resp) => {
					dispatch({type: 'task/list', payload: resp.data});
					dispatch({ type: 'responsible/wait'});
					api.getResponsibles(token, companyId)
					.then((resp) => {
						dispatch({type: 'responsible/list', payload: resp.data});
					})
				})
			})
		})
		.catch(e => console.log(e));
	}
}

export const deleteProject = (token, project) => {
	return dispatch => {
		dispatch({ type: 'project/wait'});
		api.deleteProject(token, project.projectId)
		.then(() => {
			const companyId = project.companyId
			api.getProject(token, companyId)
			.then((resp) => {
				dispatch({type: 'project/list', payload: resp.data});
				dispatch({ type: 'task/wait'});
				api.getTask(token)
				.then((resp) => {
					dispatch({type: 'task/list', payload: resp.data});
				})
			})
		})
		.catch(e => {
			console.log(e)
			const companyId = project.companyId
			api.getProject(token, companyId)
			.then((resp) => {
				dispatch({type: 'project/list', payload: resp.data});
			})
		})
	}	
}


export const getResponsibles = (token, companyId) => {
	return dispatch => {
		dispatch({ type: 'responsible/wait'});
		api.getResponsibles(token, companyId)
		.then((resp) => {
			dispatch({type: 'responsible/list', payload: resp.data});
		})
		.catch(e => console.log(e));
	}
}

export const getTask = (token) => {
	return dispatch => {
		dispatch({ type: 'task/wait'});
		api.getTask(token)
		.then((resp) => {
			dispatch({type: 'task/list', payload: resp.data});
		})
		.catch(e => console.log(e));
	}
}

export const getTaskDetails = (token, taskId) => {
	return dispatch => {
		dispatch({ type: 'project/task', payload: null});
		if(taskId !== null) {
			api.getTaskDetails(token, taskId)
			.then((resp) => {
				dispatch({type: 'project/task', payload: resp.data});
			})
			.catch(e => console.log(e));
		}
	}
}
