import React from 'react'
import { Form, ListGroup, Spinner } from 'react-bootstrap'

export function SelectCompany({companies, companyId, onSelect}) {
	
	const selected = companyId === null ? 0 : companyId;
	
	if(companies === null) {
		return (<Spinner  animation="grow" />);
	} else {
		return (
			<Form.Select value={selected} onChange={evt => onSelect(parseInt(evt.target.value))}>
				{companyId === null && <option disabled key={0} value={0}>Select Company</option> }
				{companies.map(company => {
					return (<option key={company.companyId} value={company.companyId}>{company.name}</option>);
				})}
			</Form.Select>
		);
	}
}

export function ListGroupCompany({companies, companyId, onSelect}) {

	if(companies === null) {
		return (<Spinner  animation="grow" />);
	} else {
		return (
		<ListGroup variant="flush">
			{ companies.map(company => {
				const cid = company.companyId;
				return (<ListGroup.Item action active={cid == companyId} disabled={cid == companyId} key={cid} onClick={() => onSelect(cid)}>{company.name}</ListGroup.Item>);
			})}
    	</ListGroup>
		);
	}
}