import React from 'react'
import { Accordion, Row, Col, Button, Badge, Spinner } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'

import { DraftSurvey } from '../common/draftsurvey'
import { StartedSurvey } from '../common/startedsurvey'
import { FinishedSurvey } from '../common/finishedsurvey'


function SurveyListComponent({admin, companyId, surveys, onModify, onStart, onStop, postParticipantEmail}) {
	
	const dispatch = useDispatch()
	const surveyId = useSelector((state) => state.dashboard.surveyId)

	const onSelect = (surveyId) => {
		dispatch({ type: 'dashboard/selectSurvey', payload: surveyId })
	}

	return (
		<>
		<Row className="mb-2" ><Col/><Col className="col-auto"><Button size="sm" onClick={() => dispatch({ type: 'dashboard/editsurvey', payload: {} })} >New Survey</Button></Col></Row>
		<Accordion activeKey={surveyId} onSelect={onSelect} flush>
			{surveys.map(survey => {
				const variant = survey.status === 'DRAFT' ? 'danger' : (survey.status === 'STARTED' ? 'warning' : 'success')
				return (
					<Accordion.Item key={survey.surveyId} eventKey={survey.surveyId}>
					<Accordion.Header>{survey.name}<Badge pill bg={variant} className="ms-auto me-1">{survey.status}</Badge></Accordion.Header>
					<Accordion.Body>
						{ survey.status === 'DRAFT' && <DraftSurvey onStart={onStart} onModify={onModify} companyId={companyId} survey={survey} admin={true} /> }
						{ survey.status === 'STARTED' && <StartedSurvey onClickSend={postParticipantEmail} onStopSurvey={onStop} survey={survey} admin={admin} /> }
						{ survey.status === 'FINISHED' && <FinishedSurvey survey={survey} /> }
					</Accordion.Body>
					</Accordion.Item>
				);
			})}
		</Accordion>
		</>
	);
}


export function SurveyList({admin, onModify, onStart, onStop, postParticipantEmail}) {
	
	const companyId = useSelector((state) => state.dashboard.companyId)
	const surveys = useSelector((state) => state.dashboard.surveys)
	const companies = useSelector((state) => state.dashboard.companies)
	
	if(admin) {
		if(companyId === null) {
			return (<div></div>);
		} else if(surveys === null) {
			return (<Spinner  animation="grow" />);
		} else {
			return (<SurveyListComponent admin={admin} companyId={companyId} surveys={surveys} onModify={onModify} onStart={onStart} onStop={onStop} postParticipantEmail={postParticipantEmail} />);
		}
	} else {
		if(surveys === null) {
			return (<Spinner  animation="grow" />);
		} else {
			return (<SurveyListComponent admin={admin} companyId={companyId} surveys={surveys} onModify={onModify} onStart={onStart} onStop={onStop} postParticipantEmail={postParticipantEmail} />);
		}	
	}
}