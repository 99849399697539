import { ApiClient } from './client'


export function getProject(token, companyId) {
	const client = ApiClient(token);
	const id = companyId == null ? 0 : companyId;
	return client.get('/rest/project/' + id);
}

export function postProject(token, project) {
	const client = ApiClient(token);
	return client.post('/rest/project', project);
}

export function deleteProject(token, projectId) {
	const client = ApiClient(token);
	return client.delete('/rest/project/' + projectId);
}

export function getResponsibles(token, companyId) {
	const client = ApiClient(token);
	const id = companyId == null ? 0 : companyId
	return client.get('/rest/responsibles/' + id);
}

export function getTask(token) {
	const client = ApiClient(token);
	return client.get('/rest/task');
}

export function getTaskDetails(token, taskId) {
	const client = ApiClient(token);
	return client.get('/rest/task/' + taskId);
}
