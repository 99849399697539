import React, { useRef, useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { Form, Spinner } from "react-bootstrap"

import { postReport } from '../actions'


export function SurveyDropdown({selection}) {
	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const language = useSelector((state) => state.authorization.language)
	const surveyId = useSelector((state) => state.report.surveyId)
	const compareId = useSelector((state) => state.report.compareId)
	
	const value = surveyId === null ? 0 : surveyId;
	
	if(selection === null) {
		return <Spinner animation="grow" />
	} else {
		return (
			<Form.Select aria-label="Select survey to report" value={value} onChange={evt => dispatch(postReport(token, parseInt(evt.target.value), compareId, language))}>
				{ surveyId === null  && <option disabled key={0} value={0}>Select</option> }
      			{ selection !== null && Object.entries(selection).map(([key, value]) => { 
						return (<option key={key} value={key}>{value}</option>);
   				})}
    		</Form.Select>
	    );
	}
}

export function CompareDropdown({selection}) {
	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const language = useSelector((state) => state.authorization.language)
	const surveyId = useSelector((state) => state.report.surveyId)
	const compareId = useSelector((state) => state.report.compareId)
	
	const value = compareId === null ? 0 : compareId;
	
	if(selection === null) {
		return <Spinner animation="grow" />
	} else {
		return (
			<Form.Select aria-label="Select survey to report" value={value} onChange={evt => dispatch(postReport(token, surveyId, parseInt(evt.target.value), language))}>
				{ compareId === null  && <option key={0} value={0}>Select</option> }
      			{ selection !== null && Object.entries(selection).map(([key, value]) => { 
						return (<option key={key} value={key}>{value}</option>);
   				})}
    		</Form.Select>
	    );
	}
}
