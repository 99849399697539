import { ApiClient } from './client'


export function getUsers(token) {
	const client = ApiClient(token);
	return client.get('/rest/admin/users');
}


export function postPasswordReset(token, adminId) {
	const client = ApiClient(token);
	const params = {
		personId: adminId
	}
	return client.post('/rest/admin/passwordreset', params)
}