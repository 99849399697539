import { useSelector, useDispatch } from 'react-redux'
import { CompanyMenu } from './companymenu'
import { AdminMenu } from './adminmenu'
import { PasswordModal } from './passwordmodal'

export function Menu() {

	const scope = useSelector((state) => state.authorization.scope)
	const companymenu = scope == 's_company' || scope == 'p_company' || scope == 'sp_company'
	const email = useSelector((state) => state.authorization.email)

	return (
		<>
		<PasswordModal />
		{ scope == 'admin' && <AdminMenu email={email} /> }
		{ companymenu && <CompanyMenu email={email} /> }
		</>
	)
}