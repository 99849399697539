import React, { useEffect, useReducer } from 'react'
import { Modal, Button, Form, InputGroup } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'

import { postQuestion } from '../actions'

const initialState = (topic) => {
	const initial = ({
		topicId: topic.topicId,
		questionId: topic.questionId,
		title: topic.title === null ? '' : topic.title,
		titleRef: topic.titleRef,
		description: topic.description == null ? [''] : topic.description,
		descriptionRef: topic.descriptionRef == null ? [] : topic.descriptionRef
	});
	return initial;
}

function topicReducer(state, action) {
	switch(action.type) {
		case 'state': { return initialState(action.payload); }
		case 'title': { return { ...state, title: action.payload }; }
		case 'description' : { 
			const copied = [...state.description];
			copied[action.payload.idx] = action.payload.value	
			return { ...state, description: copied}; 
		}
		case 'add' : { return { ...state, description: [...state.description, '']}; }
		case 'remove' : { return { ...state, 
			description: state.description.filter((e,i) => i != action.payload)};
		}
	}
	throw Error('Unknown action: ' + action.type);
}


export function EditTopicModal() {

	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const edititem = useSelector((state) => state.questionMgmt.edititem)
	const language = useSelector((state) => state.questionMgmt.language)
	const variant = useSelector((state) => state.questionMgmt.variant)

	const [ state, reactDispatch ] = useReducer(topicReducer, initialState({}));

	useEffect(() => {
		if(edititem != null) {
			reactDispatch({type: 'state', payload: edititem })
		}
	}, [edititem])
	
	const onSave = () => {
		dispatch(postQuestion(token, {...state, language, variant}, language, variant));
	}
	
	
	return (
		<Modal size="xl" fullscreen='lg-down' show={edititem !== null} backdrop="static">
		<Modal.Header>L1Topic</Modal.Header>
		{ edititem !== null && <Modal.Body className="bg-light">
			<Form.Group className="mb-3" controlId="email">
        		<Form.Label>Otsikko</Form.Label>
        		<Form.Control size="sm" type="text" value={state.title} onChange={evt => reactDispatch({type: 'title', payload: evt.target.value })}  />
        		<Form.Text style={{fontSize:"11px"}}>{state.titleRef}</Form.Text>
      		</Form.Group>
      		
			<Form.Group className="mb-3" controlId="description">
        		<Form.Label>Selitetekstit</Form.Label>
			{ state.description.map((d, i) => {
				return (
					<>
					<InputGroup className="mb-1" key={i}>
        			<Form.Control size="sm" type="text" value={d} controlId={'description-'+i} onChange={evt => reactDispatch({ type:'description', payload: { idx: i, value: evt.target.value}})} />
        			<Button size="sm" variant="outline-danger" onClick={() => reactDispatch({type:'remove', payload: i})}> 
        				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
        				<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"/>
        				</svg>
        			</Button>
        			</InputGroup>
        			</>
				);
			})}
			<Form.Text style={{fontSize:"11px"}}>{state.descriptionRef.map(d => d + ' ')}</Form.Text>
   			</Form.Group>
			<Button size="sm" variant="outline-success" onClick={() => reactDispatch({type:'add'})}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-plus" viewBox="0 0 16 16">
  					<path d="M8.5 6a.5.5 0 0 0-1 0v1.5H6a.5.5 0 0 0 0 1h1.5V10a.5.5 0 0 0 1 0V8.5H10a.5.5 0 0 0 0-1H8.5V6z"/>
  					<path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
				</svg>
            </Button>
		</Modal.Body> }
		<Modal.Footer>
			<Button variant="secondary" onClick={() => dispatch({ type: 'questionmgmt/edititem', payload: null })}>Peruuta</Button>
			<Button variant="primary" onClick={() => onSave()}>OK</Button>
		</Modal.Footer>
		</Modal>

	)
}