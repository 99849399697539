import { createSlice } from '@reduxjs/toolkit'

export const adminSlice = createSlice({
	name: 'admin',
	initialState: {
		surveys: null,
		users: null,
		passwordreset: null,
	},
	reducers: {
		surveys: (state, action) => {
			state.surveys = action.payload
			state.participant = null
		},
		users: (state, action) => {
			state.users = action.payload
			state.passwordreset = null
		},
		wait: (state, action) => {
			state.surveys = null
			state.users = null
		},
		passwordreset: (state, action) => {
			state.passwordreset = action.payload == '' ? null : action.payload
		}		
	}
})

export const { surveys, users, wait } = adminSlice.actions;

export default adminSlice.reducer