import { createSlice } from '@reduxjs/toolkit'

export const responsibleSlice = createSlice({
	name: 'responsible',
	initialState: {
		responsibles: []
	},
	reducers: {
		wait: (state, action) => {
			state.responsibles = null
		},
		list: (state, action) => {
			state.responsibles = action.payload
		}
	}
	
})

export const { wait, list } = responsibleSlice.actions;

export default responsibleSlice.reducer;