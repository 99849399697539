import React, { useRef, useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { Container, Spinner, Form, Row, Col, Navbar, Nav, Table, Accordion, Badge, Tabs, Tab } from "react-bootstrap"


function L1ReportTable({l1result}) {
	return (
		l1result.map(entry =>{
			return (
				<tr key={entry.id} >
					<td key={0} style={{'textAlign':'left'}}>{entry.title}</td><td style={{'textAlign':'left'}}>(n={entry.n-entry.zeroes}/{entry.n})</td>
					{entry.quartiles.map((value, i) => {
						return <td key={i}>{value}</td>
					})}
				</tr>
			)
		})
	)
}

function SurveyReportTable() {

	const dispatch = useDispatch()
	const result = useSelector((state) => state.report.result)
	const l1Id = useSelector((state) => state.report.l1Id)
	
	return (
		<Accordion flush activeKey={l1Id} onSelect={l1Id => dispatch({ type: 'report/selectl1', payload: l1Id }) }>
			{result.map( (entry) =>{
				return (
					<Accordion.Item key={entry.id} eventKey={entry.id}>
					<Accordion.Header>{entry.title}<Badge pill bg="dark" className="ms-auto me-1">{entry.quartiles[2]}</Badge></Accordion.Header>
					<Accordion.Body>
						<Table className='report-table' style={{'fontSize':'12px', 'padding': '0.5rem 0'}}>
						<tbody>
						{ entry.id === l1Id && <L1ReportTable l1result={entry.details}/> }
						</tbody>
						<tfoot>
						<tr key={0} ><th/><th style={{'textAlign':'left'}}>(n={entry.n-entry.zeroes}/{entry.n})</th>
						{entry.quartiles.map((value, i) => {
							return <th key={i}>{value}</th>
						})}
						</tr>
						</tfoot>
					</Table>
					</Accordion.Body>
					</Accordion.Item >
				)
			})}
		</Accordion>
	);
}


export function SurveyReport() {

	const surveyId = useSelector((state) => state.report.surveyId)
	const result = useSelector((state) => state.report.result)
	
	const loading = result === null && surveyId !== null
	
	return (
		<>
		{ loading && <Spinner animation="grow" /> }
		{ result !== null && <SurveyReportTable /> }
		</>
	)
}


function L1ReportCompareTable({l1result}) {
	return (
		l1result.map(entry =>{
			return (
				<tr key={entry.id} >
					<td key={0} style={{'textAlign':'left'}}>{entry.title}</td><td style={{'textAlign':'left'}}>(n={entry.compareN-entry.compareZeroes}/{entry.compareN})</td>
					{entry.compareQuartiles.map((value, i) => {
						return <td key={i}>{value}</td>
					})}
				</tr>
			)
		})
	)
}


function SurveyReportCompareTable() {

	const dispatch = useDispatch()
	const result = useSelector((state) => state.report.result)
	const l1Id = useSelector((state) => state.report.l1Id)
	
	return (
		<Accordion flush activeKey={l1Id} onSelect={l1Id => dispatch({ type: 'report/selectl1', payload: l1Id }) }>
			{result.map( (entry) =>{
				return (
					<Accordion.Item key={entry.id} eventKey={entry.id}>
					<Accordion.Header>{entry.title}<Badge pill bg="dark" className="ms-auto me-1">{entry.compareQuartiles[2]}</Badge></Accordion.Header>
					<Accordion.Body>
						<Table className='report-table' style={{'fontSize':'12px', 'padding': '0.5rem 0'}}>
						<tbody>
						{ entry.id === l1Id && <L1ReportCompareTable l1result={entry.details}/> }
						</tbody>
						<tfoot>
						<tr key={0} ><th/><th style={{'textAlign':'left'}}>(n={entry.compareN-entry.compareZeroes}/{entry.compareN})</th>
						{entry.compareQuartiles.map((value, i) => {
							return <th key={i}>{value}</th>
						})}
						</tr>
						</tfoot>
					</Table>
					</Accordion.Body>
					</Accordion.Item >
				)
			})}
		</Accordion>
	);
}



export function SurveyCompareReport() {

	const result = useSelector((state) => state.report.result)
	const compareId = useSelector((state) => state.report.compareId)

	
	const loading = result === null && compareId !== null
	
	return (
		<>
		{ loading && <Spinner animation="grow" /> }
		{ result !== null && compareId !== null && <SurveyReportCompareTable /> }
		</>
	)
}



