import * as api from '../api/'

export const fetchQuestion = (token,url) => {
	return dispatch => {
		api.getQuestion(token,url)
		.then(resp => {
			dispatch({
				type: 'question/store',
				payload: resp.data
			});
		})
		.catch(e => {
			dispatch({ type: 'question/error', payload: e.message });
		});
	}
}

export const postAnswer = (token, url, answerId, result, comment, nextId) => {
	return dispatch => {
		dispatch({ type: 'question/wait', payload: null })
		api.postAnswer(token, url, answerId, result, comment, nextId)
		.then(resp => {
			dispatch({
				type: 'question/store',
				payload: resp.data
			});
		})
		.catch(e => {
			dispatch({ type: 'question/error', payload: e.message });
		});
	}
}


