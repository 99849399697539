import { createSlice } from '@reduxjs/toolkit'

export const projectSlice = createSlice({
	name: 'project',
	initialState: {
		projects: [],
		edit: null,
		selected: null,
		task: null,  /* task selected from project's task list */
		tobedeleted: null,
		save_select: null,		
	},
	reducers: {
		wait: (state, action) => {
			state.projects = null
			state.save_select = state.selected == null ? null : state.selected.projectId
			state.selected = null
			state.edit = null
			state.tobedeleted = null
			state.task = null
		},
		list: (state, action) => {
			const projects = action.payload == null ? [] : action.payload
			const id = state.save_select == null ? null : state.save_select
			const selected = projects.find(entry => entry.projectId === id)
			state.projects = projects
			state.selected = selected == null ? null : selected
			state.save_select = null
			state.task = null
		},
		edit: (state, action) => {
			state.edit = action.payload
		},
		delete: (state, action) => {
			state.tobedeleted = action.payload
		},
		select: (state, action) => {
			state.selected = action.payload
		},
		task: (state, action) => {
			state.task = action.payload
		}
	}
})

export const { wait, list, edit, select } = projectSlice.actions;

export default projectSlice.reducer