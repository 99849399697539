import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, ListGroup, Card, Badge, Accordion, Form, Button, Spinner } from 'react-bootstrap';
import { StatusBadge, UpdatedBadge, StatusRange, Responsible } from './misc'
import { TaskStatusModal } from './taskstatusmodal'

import { getTask, getTaskDetails,  deleteProject } from '../actions'


export function TaskDetails({task}) {
	
	const [edit, setEdit] = useState(null)
	
	return (
		<>
			<TaskStatusModal task={edit} onClose={() => setEdit(null)} />
			<Card className='mb-2'>
				<Card.Header>
					<Container>
						<Row>
							<Col className="me-auto">{task.name}</Col>
							<Col xs='auto'><StatusBadge task={task} /></Col>
						</Row>
					</Container>
				</Card.Header>
			<Card.Body>
				<TaskBody task={task} />
			</Card.Body>
			<Card.Footer>
				<Container>
					<Row>
						<Col className="ms-auto pe-0" md="auto"><Button size="sm" onClick={() => setEdit(task)}>Päivitä tilanne</Button></Col>
					</Row>
				</Container>
			</Card.Footer>
			</Card>
		</>
	)
}

export function TaskBody({task}) {
	
	const labelstyle = {
		'fontSize': '10px',
		'fontWeight': '700',
		'margin': 'auto 0px'
	}

	const style = {
		fontSize: '10px',
		border: '1px solid #ced4da',
		borderRadius: '0.375rem',
		padding: '0.375rem 0.75rem',
		minHeight: '1.5rem'
	}
	
	return (
		<>
		<Row>
			<Col style={labelstyle} md={2}>Aloitus</Col><Col style={style}>{task.begin}</Col>
			<Col style={labelstyle} md="auto">Tavoiteaika</Col><Col style={style}>{task.deadline}</Col>
		</Row>
		<Row className="mt-2">
			<Col style={labelstyle} md={2}>Vastuuhenkilö</Col><Col style={style}><Responsible task={task} /></Col>
		</Row>
		<Row className="mt-2"><Col style={labelstyle} md={2}>Tehtävän kuvaus</Col><Col style={style}>{task.description}</Col></Row>
		<Row className="mt-2"><Col style={labelstyle} md={2}>Valmiusaste</Col><Col><StatusRange task={task} /></Col></Row>
		<Row className="mt-2"><Col style={labelstyle} md={2}>Tilanne</Col><Col style={style}>{task.status}</Col></Row>
		{ task.tasks && <SubTasks tasks={task.tasks}  /> }
		</>
	)
}

export function OwnTasks() {
	
	const token = useSelector((state) => state.authorization.token)
	const dispatch = useDispatch()
	const tasks = useSelector((state) => state.task.own)
	const selected = useSelector((state) => state.task.selected)

	useEffect(() => {
		if(token !== null) {
			dispatch(getTask(token))
		}
	}, [token])

	if(tasks === null) {
		return <Spinner animation='grow' />
	} else {
		const accordionSelected = selected === null ? null : selected.tasks === null ? selected.parentId : selected.projectId;
		return (
			<Card>
			<Card.Header>Omat tehtävät</Card.Header>
			<Accordion flush activeKey={accordionSelected}>
			<ListGroup variant='flush' className="mb-2">
				{ tasks.map(item =>  {
					return (
						<React.Fragment key={item.projectId}>
						{ item.tasks != null && item.tasks.length == 1 && <SingleTask project={item} /> }
						{ item.tasks != null && item.tasks.length > 1 && <MultipleTasks project={item} /> }
						</React.Fragment>
					);
				})}
			</ListGroup>
			</Accordion>
			</Card>
		)
	}
}


function SingleTask({project}) {
	
	const dispatch = useDispatch()
	const selected = useSelector((state) => state.task.selected)

	const tid = selected === null ? null : selected.projectId;
	const task = project.tasks[0]
	const id = task.projectId;
	const tbsel = tid == id ? null : task;
	
	const projectstyle = {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		fontSize: '10px'
	}

	const namestyle = {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis'
	}
	
	return (
		<ListGroup.Item action active={tid == id} key={id} style={namestyle}>
		<Container>
		<Row onClick={() => dispatch({type: 'task/select', payload: tbsel})} >
			<Col className="me-auto" style={namestyle}>
				<Row style={projectstyle}>{project.name}</Row>
				<Row style={namestyle}>{task.name}</Row>
			</Col>
			<Col xs='auto'  className='pe-1 my-auto'><StatusBadge task={task} /></Col>
		</Row>
		</Container>
		</ListGroup.Item>
	)	
}

function MultipleTasks({project}) {

	const dispatch = useDispatch()
	const selected = useSelector((state) => state.task.selected)
	const tid = selected === null ? null : selected.projectId;
	const projsel = tid == project.projectId ? null : project;

	const namestyle = {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis'
	}
	
	const accordionstyle = {
		paddingTop: '0',
		paddingBottom: '0'
	}
	
	return (
		<Accordion.Item eventKey={project.projectId} >
        <Accordion.Header onClick={() => dispatch({type: 'task/select', payload: projsel})}>
			<div style={namestyle} className="me-auto">{project.name}</div>
		</Accordion.Header>
        <Accordion.Body style={accordionstyle}>
        <ListGroup variant='flush'>
        {project.tasks.map(task => {
			const id = task.projectId;
			const tbsel = tid == id ? project : task;
			return (
				<ListGroup.Item action active={tid == id} key={id} style={namestyle} onClick={() => dispatch({type: 'task/select', payload: tbsel})} >
				<Container>
				<Row>
					<Col style={namestyle} className="me-auto">{task.name}</Col>
					<Col xs='auto' className='pe-1'><StatusBadge task={task} /></Col>
				</Row>
				</Container>
				</ListGroup.Item>
			)
		})}
        </ListGroup>
        </Accordion.Body>
        </Accordion.Item>
	)
}


export function TaskList({tasks}) {

	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const taskdetail = useSelector((state) => state.project.task)
	const [taskId, setTaskId] = useState(null);
	const [edit, setEdit] = useState(null)
	
	const itemstyle= {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		fontWeight: '700',
		fontSize: '12px'
	}
	
	const selectTask = taskId => {
		setTaskId(taskId);
		dispatch(getTaskDetails(token, taskId));
	}
	
	if(tasks != null) {
	return (
	<Card>
		<TaskStatusModal task={edit} onClose={() => setEdit(null)} />
		<Accordion activeKey={taskId} onSelect={selectTask}>
		{tasks.map(task => {
			return (
				<Accordion.Item key={task.projectId} eventKey={task.projectId}>
				<Accordion.Header ><div style={itemstyle} className="me-auto"><UpdatedBadge task={task} /> {task.name}</div><StatusBadge task={task} /></Accordion.Header>
				<Accordion.Body>
					{ taskdetail === null && <Spinner animation='grow' /> }
					{ taskdetail !== null && 
					<>
					<TaskBody task={taskdetail} />
					<Row className="mt-2">
						<Col className="ms-auto pe-0" md="auto">
							<Button style={{fontSize: '0.7rem'}} size="sm" variant="danger" onClick={() => dispatch(deleteProject(token, task))}>Poista tehtävä</Button>
						</Col>
						<Col className="pe-0" md="auto">
							<Button style={{fontSize: '0.7rem'}}  size="sm" onClick={() => setEdit(taskdetail)}>Päivitä tilanne</Button>
						</Col>
						<Col className="pe-0" md="auto">
							<Button style={{fontSize: '0.7rem'}} size="sm" onClick={() => dispatch({type: 'task/edit', payload: taskdetail})}>Muokkaa</Button>
						</Col>
					</Row>
					</> }
				</Accordion.Body>
				</Accordion.Item>
			)
		})}
		</Accordion>				
	</Card>
	)}
}
