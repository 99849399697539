import react, { useState } from 'react'
import { Modal, Container, Row, Col, Button, FloatingLabel, Form } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { postPassword } from '../actions'

export function PasswordModal() {

	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const email = useSelector((state) => state.authorization.email)
	const show = useSelector((state) => state.password.show)
	const error = useSelector((state) => state.password.error)

	const [ oldPass, setOldPass ] = useState('');
	const [ newPass1, setNewPass1 ] = useState('');
	const [ newPass2, setNewPass2 ] = useState('');

	const typeOldPass = (evt) => {
		setOldPass(evt.target.value);
	}

	const typeNewPass1 = (evt) => {
		setNewPass1(evt.target.value);
	}

	const typeNewPass2 = (evt) => {
		setNewPass2(evt.target.value);
	}
	
	const pressChange = () => {
		dispatch(postPassword(token, oldPass, newPass1, newPass2));
	}

	return (
		<Modal show={show} backdrop="static">
		<Modal.Header>Change Password for {email}</Modal.Header>
		<Modal.Body className="bg-light">
	      	<FloatingLabel controlId="oldPass" label="Current Password" className="mb-3"  >
    			<Form.Control type="password" value={oldPass} onChange={typeOldPass} />
	      	</FloatingLabel>
	      	<FloatingLabel controlId="newPass1" label="New Password" className="mb-3"  >
    			<Form.Control type="password" value={newPass1} onChange={typeNewPass1} />
	      	</FloatingLabel>
	      	<FloatingLabel controlId="newPass2" label="Repeat New Password" className="mb-3"  >
    			<Form.Control type="password" value={newPass2} onChange={typeNewPass2} />
	      	</FloatingLabel>
	      	{ error && <h4 style={{'color': 'red'}} className="text-center mb-4">Login incorrect</h4> }
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={() => dispatch({type: 'password/success'})} >Close</Button>
			<Button variant="primary" disabled={newPass1 != newPass2} onClick={pressChange} >Change</Button>
		</Modal.Footer>
		</Modal>
	)	

}