import React, { useRef, useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'

import { DrawXAxis, DrawYAxis, DrawData, RemoveAxis, DrawIndex } from "./graphutil"

export function SurveyGraph({onClick}) {

  	const svgRef = useRef(null);

	const result = useSelector((state) => state.report.result)
	const average = useSelector((state) => state.report.average)
  	
	useEffect(() => {
		/* console.log('SurveyGraph.useEffect', result) */
		if(result === null) {
			RemoveAxis(svgRef);
		} else {
			DrawXAxis(svgRef, 'result', result, 50, 150);
			DrawYAxis(svgRef, result, 50, 150);
			if(result !== null && result.length > 0) {
				DrawData(svgRef, result, 50, 150);
			} 
			DrawIndex(svgRef, 25, average);
		}
	}, [result])

	if(result) {
	return (
		<>
		<svg className="d-block mx-auto" viewBox="-175,-175,350,350" strokeLinejoin="round" strokeLinecap="round" ref={svgRef} style={{ background: "white" }} onClick={onClick} >
			<g className="xaxis"/>
			<g className="yaxis"/>
			<g className="index"/>
			<g className="data" />
   		</svg>
		</>
	)} else {
		return null;
	}
}
