import React, { useRef, useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { Container, Spinner, Row, Col, Tabs, Tab } from "react-bootstrap"

import { Menu } from "../menu"
import { LoginModal } from '../loginview'
import { postReport, postReportL1 } from '../actions'
import { GraphModal } from './graphmodal'

import { SurveyDropdown, CompareDropdown } from './surveydropdowns'
import { SurveyReport, SurveyCompareReport } from './surveyreport'
import { SurveyGraph } from './surveygraph'

export default function ReportView() {
	
	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const language = useSelector((state) => state.authorization.language)
	const selection = useSelector((state) => state.report.selection)
	const surveyId = useSelector((state) => state.report.surveyId)
	const compareId = useSelector((state) => state.report.compareId)

	const [showModal, setShow] = useState(false)
	
	useEffect(() => {
		if(token !== null) {
			dispatch(postReport(token, surveyId, compareId, language))
		}
	}, [token, language])

	return (
		<>
		<Menu />
		<LoginModal />
		<GraphModal show={showModal} onClose={() => setShow(false)} />
			<Container>
				<Tabs defaultActiveKey="report" className="mb-3">
					<Tab eventKey="report" title="Report">
						<Row><Col><SurveyDropdown selection={selection} /></Col></Row>
						<Row>
							<Col className="mt-2" md={6} >
								<SurveyReport />
							</Col>
							<Col className="mt-2" md={6}>
								<SurveyGraph onClick={() => setShow(true)} />
							</Col>
						</Row>
					</Tab>
					{ false && <Tab eventKey="sort" title="Ordered Report">
						<Row><Col><SurveyDropdown selection={selection} /></Col></Row>
						<Row><h4>Not implemented yet - work in progress </h4></Row>
					</Tab> }
					<Tab eventKey="compare" title="Compare Reports">
						<Row><Col><SurveyDropdown selection={selection} /></Col><Col><CompareDropdown selection={selection} /></Col></Row>
						<Row>
							<Col className="mt-2" md={6} >
								<SurveyReport />
							</Col>
							<Col className="mt-2" md={6}>
								<SurveyCompareReport />
							</Col>
						</Row>
					</Tab>
				</Tabs>
			</Container>
		
		</>
	)
}