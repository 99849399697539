import React, { useEffect} from "react"
import { Container, Form, Row, Col, Accordion, Badge, Table, Button, Spinner } from "react-bootstrap"
import { FileDrop } from './filedrop'


export function DraftSurvey({companyId, survey, onModify, onStart, admin}) {
	const id = survey.surveyId
	return(
	<>
	<Row className="mb-2">
	<Col xs={12} sm={8}>
		<Form.Group controlId={'name-'+id} >
			<Form.Label>Nimi</Form.Label>
			<Form.Control value={survey.name} readOnly />		
		</Form.Group>
	</Col>
	<Col>
		<Form.Group as={Col} controlId={'deadline-'+id}>
			<Form.Label>Päättymisaika</Form.Label>
			<Form.Control value={survey.deadLineDate} readOnly />		
		</Form.Group>
	</Col>
	</Row>
		<Row>
		<Form.Group as={Col} controlId={'variant-'+id}>
			<Form.Label>Laajuus</Form.Label>		
			<Form.Select value={survey.variant} disabled readOnly>
      			<option value="LAAJA">Esihenkilöstö</option>
      			<option value="SUPPEA">Henkilöstö</option>
    		</Form.Select>
		</Form.Group>
		<Form.Group as={Col} controlId={'language-'+id}>
			<Form.Label>Kieli</Form.Label>
			<Form.Select value={survey.language} disabled readOnly >
      			<option value="fi">Finnish</option>
      			<option value="en">English</option>
      			<option value="et">Estonian</option>
      			<option value="lv">Latvian</option>
      			<option value="lt">Lithuanian</option>
    		</Form.Select>
		</Form.Group>
		<Form.Group as={Col} controlId={'description-'+id}>
			<Form.Label>Selitetekstit</Form.Label>		
			<Form.Select value={survey.descriptions} disabled readOnly >
      			<option value={false}>Pois</option>
      			<option value={true}>Käytössä</option>
    		</Form.Select>
		</Form.Group>
	</Row>
	<Row className="mb-4">
		<Form.Group as={Col} controlId={'participants-'+id}>
			<Form.Label>Osallistujia</Form.Label>
			<Form.Control value={survey.participantsAll} readOnly />		
		</Form.Group>
	</Row>
	<Row className="mb-2">
		<Col xs="auto"  className="pe-0">
		<Button onClick={() => onModify(survey)}variant="secondary" size="sm">Muokkaa</Button>
		<Button onClick={() => onStart(survey.surveyId)} className="ms-2" variant="warning" size="sm">Käynnistä</Button>
		</Col>
		<Col>
			<FileDrop companyId={companyId} surveyId={survey.surveyId} admin={admin} />
		</Col>
	</Row>
			
	</>
	);
}