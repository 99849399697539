import * as api from '../api/'


export const getUsers = (token) => {
	return dispatch => {
		dispatch({ type: 'admin/wait'});
		api.getUsers(token)
		.then(resp => {
			dispatch({
				type: 'admin/users',
				payload: resp.data
			});
		})
		.catch(e => console.log(e));
	}
}


export const postPasswordReset = (token, adminId) => {
	return dispatch => {
		dispatch({ type: 'admin/passwordreset', payload: { adminId: adminId, wait: true }});
		api.postPasswordReset(token, adminId)
		.then(resp => {
			dispatch({
				type: 'admin/passwordreset',
				payload: resp.data
			});
		})
		.catch(e => console.log(e));
	}
} 
