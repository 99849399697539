import * as api from '../api/'

export const getQuestions = (token, language, variant) => {
	return dispatch => {
		dispatch({ type: 'questionmgmt/waitQuestions', payload: null });
		api.getQuestions(token, language, variant)
		.then(resp => {
			dispatch({
				type: 'questionmgmt/questions',
				payload: resp.data
			});
		})
		.catch(e => console.log(e));
	}
}

export const postQuestion = (token, topic, language, variant) => {
	return dispatch => {
		dispatch({ type: 'questionmgmt/waitQuestions', payload: null });
		api.postQuestion(token, topic)
		.then(() => {
			api.getQuestions(token, language, variant)
				.then(resp => {
					dispatch({
						type: 'questionmgmt/questions',
						payload: resp.data
					});
				}).catch(e => console.log(e));
		}).catch(e => console.log(e));
	}
}