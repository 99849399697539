
export function parseDate(string) {
	const splitted = string.split('.');
	console.log('parseDate', string, splitted)
	if(splitted.length == 3) {
		const reversed = [splitted[1], splitted[0], splitted[2]];
		const reversed_date = reversed.join('.');
		console.log(reversed, reversed_date);
		const setdate = new Date(reversed_date);
		setdate.setHours(12);
		console.log(setdate);
		return setdate;
	} else {
		return new Date()
	}
}
