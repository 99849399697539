export function initialProjectState(project){
	
	const begindate = project == null || project.begin == null ? new Date() : new Date(project.begin);
	const deadlinedate = project == null || project.deadline == null ? new Date() : new Date(project.deadline);

	return {
		projectId: project == null || project.projectId == null ? null : project.projectId,
		companyId: project == null || project.companyId == null ? null : project.companyId,
		version: project == null || project.version == null ? 0 : project.version,
		name: project == null || project.name == null ? '' : project.name,
		begin: begindate,
		deadline: deadlinedate,
		responsible: project == null || project.responsible == null ? '0' : project.responsible,
		responsibleEmail: '',
		responsibleUsername: '',
		description: project == null || project.description == null ? '' : project.description,
		status: project == null || project.status == null ? '' : project.status,
		start: project == null || project.start == null ? '' : project.start,
		measure: project == null || project.measure == null ? '' : project.measure,
		tasks: [],
		namevalid: false,
		responsiblevalid: false,
		responsibleEmailvalid: false,
		responsibleUsernamevalid: false,
		problemvalid: false,
		startvalid: false,
		measurevalid: false,
		valid: false,
		validation: false
	};
}

export function projectReducer(state, action) {
	
	const newstate = isValid({ ...state, ...action.payload});
	
	/* console.log('projectReducer', action.payload, newstate); */
	
	return newstate;	
}

function isValid(state) {
	
	const namevalid = state.name == null ? false : (state.name.length > 5 && state.name.length < 250);
	const responsiblevalid = !(state.responsible == null || state.responsible == '0');
	const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	const responsibleEmailvalid = state.responsibleEmail == null ? false : state.responsibleEmail.match(validEmailRegex);
	const responsibleUsernamevalid = state.responsibleUsername == null ? false : state.responsibleUsername.length > 2;
	const descriptionvalid = state.description == null ? false : (state.description.length > 5 && state.description.length < 1000);
	const startvalid = state.start == null ? false : (state.start.length > 5 && state.start.length < 1000);
	const measurevalid = state.measure == null ? false : (state.measure.length > 5 && state.measure.length < 1000);
	const valid = namevalid && responsiblevalid && descriptionvalid && startvalid && measurevalid && 
			(state.responsible != '-1' || (responsibleEmailvalid && responsibleUsernamevalid ));
	
	return { ...state,
		namevalid: namevalid,
		responsiblevalid: responsiblevalid,
		responsibleEmailvalid: responsibleEmailvalid,
		responsibleUsernamevalid: responsibleUsernamevalid,
		descriptionvalid: descriptionvalid,
		startvalid: startvalid,
		measurevalid: measurevalid,
		valid: valid
	};
}
