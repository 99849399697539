import react, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'


export function ConfirmationModal({show, title, text, onCancel, onOk}) {

	return(
		<Modal show={show} backdrop="static">
		<Modal.Header>{title}</Modal.Header>
		<Modal.Body>{text}</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={onCancel}>Cancel</Button>
			<Button variant="warning" onClick={onOk}>OK</Button>
		</Modal.Footer>
		</Modal>
	)
}