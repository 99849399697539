import * as api from '../api/'


export const postSurveyStatus = (token, companyId) => {
	return dispatch => {
		if(companyId === null) {
			dispatch({ type: 'dashboard/waitCompanies'});
		} else {
			dispatch({ type: 'dashboard/selectCompany', payload: companyId});
		}
		api.postSurveyStatus(token, companyId)
		.then(resp => {
			dispatch({
				type: 'dashboard/companies',
				payload: resp.data
			});
		})
		.catch(e => console.log(e));
	}
}


export const postSurveyStart = (token, companyId, surveyId) => {
	return dispatch => {
		dispatch({ type: 'dashboard/waitSurveys'});
		api.postSurveyStart(token, surveyId)
		.then(() => {
			api.postSurveyStatus(token, companyId)
			.then(resp => {
				dispatch({
					type: 'dashboard/companies',
					payload: resp.data
				});
			})
		})
		.catch(e => console.log(e));
	}
}


export const postSurveyStop = (token, companyId, surveyId) => {
	return dispatch => {
		dispatch({ type: 'dashboard/waitSurveys'});
		api.postSurveyStop(token, surveyId)
		.then(() => {
			api.postSurveyStatus(token, companyId)
			.then(resp => {
				dispatch({
					type: 'dashboard/companies',
					payload: resp.data
				});
			})
		})
		.catch(e => console.log(e));
	}
}


export const postEmailAttachment = (token, companyId, surveyId, contenttype, file) => {
	return dispatch => {
		dispatch({ type: 'dashboard/waitSurveys'});
		const reader = new FileReader();
		reader.onload = event => {
			api.postEmailAttachment(token, surveyId, contenttype, event.target.result)
			.then(() => {
				api.postSurveyStatus(token, companyId)
					.then(resp => { dispatch({ type: 'dashboard/companies', payload: resp.data }) })
					.catch(e => console.log(e));
			}).catch(e => console.log(e));
		}
		reader.readAsArrayBuffer(file);
	}
}


export const postParticipantEmailSent = (token, companyId, surveyId, personId) => {
	return dispatch => {
		dispatch({ type: 'dashboard/participant', payload: { wait: true }});
		api.postParticipantEmailSent(token, surveyId, personId)
		.then(resp => {
			dispatch({ type: 'dashboard/participant', payload: resp.data });
			api.postSurveyStatus(token, companyId)
				.then(resp => { dispatch({ type: 'dashboard/companies', payload: resp.data }) })
				.catch(e => console.log(e));
		})
		.catch(e => console.log(e));
	}
} 


export const postSurvey = (token, companyId, survey) => {
	return dispatch => {
		dispatch({ type: 'dashboard/waitSurveys'});
		api.postSurvey(token, survey)
		.then(() => {
			api.postSurveyStatus(token, companyId)
			.then(resp => {
				dispatch({
					type: 'dashboard/companies',
					payload: resp.data
				});
			})
		})
		.catch(e => console.log(e));
	}
}


