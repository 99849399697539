import react, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux"
import store from "./reducers/store"
import { Localization } from "react-widgets";
import { DateLocalizer, NumberLocalizer } from 'react-widgets/IntlLocalizer';


import App from "./App";


import "react-widgets/styles.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<Localization date={new DateLocalizer({ culture: 'fi-FI', firstOfWeek: 1 })} number={new NumberLocalizer({ culture: 'fi-FI' })}>
  		<Provider store={store}>
    		<App />
    	</Provider>
    </Localization>
);

/*
  <StrictMode>
	<Localization date={new DateLocalizer({ culture: 'fi-FI', firstOfWeek: 1 })} number={new NumberLocalizer({ culture: 'fi-FI' })}>
  		<Provider store={store}>
    		<App />
    	</Provider>
    </Localization>
  </StrictMode>



*/