import { ApiClient } from './client'

export function getQuestion(token, url) {
	const params = {
		url: url
	}
	const client = ApiClient(token);
	return client.post('/', params);
}

export function postAnswer(token, url, answerId, result, comment, nextId) {
	const params = {
		url: url,
		answerId: answerId,
		result: result,
		comment: comment,
		nextId: nextId
	};

	const client = ApiClient(token);
	return client.post('/', params);
}

