import * as api from '../api/'
export * from './adminactions'
export * from './companyactions'
export * from './surveyactions'
export * from './reportactions'
export * from './dashboardactions'
export * from './questionmgmtactions'
export * from './projectactions'

export const postLogin = (username, password) => {
	return dispatch => {
		api.postLogin(username, password)
			.then(resp => {
				dispatch({
					type: 'authorization/login',
					payload: resp.data
				});
			}).catch(error => {
				dispatch({
					type: 'authorization/error',
					payload: error.message
				});
			})
		}
}

export const postPassword = (token, old, pass1, pass2) => {
	return dispatch => {
		dispatch({type: 'password/wait'});
		api.postPassword(token, old, pass1, pass2)
			.then(resp => {
				dispatch({
					type: 'password/success',
					payload: resp.data
				});
			}).catch(error => {
				dispatch({
					type: 'password/error',
					payload: error.message
				});
			})
		}
}

export const postPasswordByLink = (token, pass1, pass2) => {
	return dispatch => {
		dispatch({type: 'password/wait'});
		api.postPassword(token, '', pass1, pass2)
			.then(resp => {
				window.location.replace("/")
			}).catch(error => {
				dispatch({
					type: 'password/error',
					payload: error.message
				});
			})
		}
}

