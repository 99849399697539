import { configureStore } from '@reduxjs/toolkit'
import questionReducer from './questionslice'
import surveyReducer from './surveyslice'
import authorizationReducer from './authorizationslice'
import adminReducer from './adminslice'
import passwordReducer from './passwordslice'
import reportReducer from './reportslice'
import dashboardReducer from './dashboardslice'
import questionMgmtReducer from './questionmgmtslice'
import projectReducer from './projectslice'
import taskReducer from './taskslice'
import responsibleReducer from './responsibleslice'

export default configureStore({
	reducer: {
		question: questionReducer,
		survey: surveyReducer,
		authorization: authorizationReducer,
		password: passwordReducer,
		admin: adminReducer,
		report: reportReducer,
		dashboard: dashboardReducer,
		questionMgmt: questionMgmtReducer,
		project: projectReducer,
		task: taskReducer,
		responsible: responsibleReducer
	}
})