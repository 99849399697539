import React, { useEffect, useReducer } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { DatePicker } from 'react-widgets';

import { postSurvey } from '../actions'
import { parseDate} from '../common/parsedate';



const initialState = (survey) => {
	const initial = ({
		surveyId: survey.surveyId,
 		name: survey.name == null ? '' : survey.name,
		deadLineDate: survey.deadLineDate == null ? new Date() : new Date(survey.deadLineDate),
		variant: survey.variant == null ? 'LAAJA' : survey.variant,
		language: survey.language == null ? 'fi' : survey.language,
		descriptions: survey.descriptions == null ? true : survey.descriptions
	});
	/* console.log(initial); */
	return initial;
}

function surveyReducer(state, action) {
	/* console.log('surveyReducer ', action) */
	switch(action.type) {
		
		case 'state': { return initialState(action.payload); }
		
		case 'name': { return { ...state, name: action.payload }; } 

		case 'deadLineDate': { return { ...state, deadLineDate: action.payload }; } 

		case 'variant': { return { ...state, variant: action.payload }; } 

		case 'language': { return { ...state, language: action.payload }; } 

		case 'descriptions': { return { ...state, descriptions: action.payload }; } 
	}
	throw Error('Unknown action: ' + action.type);
}



function SurveyModalBody({state, dispatch}) {
	
	return (
		<>
		<Row className="mb-2">
		<Col xs={12} sm={8}>
		<Form.Group controlId='name' >
			<Form.Label>Nimi</Form.Label>
			<Form.Control value={state.name} onChange={evt => dispatch({type: 'name', payload: evt.target.value })} />		
		</Form.Group>
		</Col>
		<Col>
		<Form.Group controlId='deadline'>
			<Form.Label>Päättymisaika</Form.Label>
			<DatePicker
                        id="begindate-input"
                        value={state.deadLineDate}
                        parse={parseDate}
                        onChange={(e) => dispatch({type: 'deadLineDate', payload: e })}
                      />
		</Form.Group>
		</Col>
		</Row>
		<Row>
		<Form.Group as={Col} controlId='variant'>
			<Form.Label>Laajuus</Form.Label>		
			<Form.Select value={state.variant} onChange={evt => dispatch({type: 'variant', payload: evt.target.value })} aria-label="Select variant">
      			<option value="LAAJA">Esihenkilöstö</option>
      			<option value="SUPPEA">Henkilöstö</option>
    		</Form.Select>
		</Form.Group>
		<Form.Group as={Col} controlId='language'>
			<Form.Label>Kieli</Form.Label>
			<Form.Select value={state.language} onChange={evt => dispatch({type: 'language', payload: evt.target.value })} aria-label="Select language">
      			<option value="fi">Finnish</option>
      			<option value="en">English</option>
      			<option value="et">Estonian</option>
      			<option value="lv">Latvian</option>
      			<option value="lt">Lithuanian</option>
    		</Form.Select>
		</Form.Group>
		<Form.Group as={Col} controlId='description'>
			<Form.Label>Selitetekstit</Form.Label>		
			<Form.Select value={state.descriptions} onChange={evt => dispatch({type: 'descriptions', payload: evt.target.value })} aria-label="Select description texts">
      			<option value={false}>Pois</option>
      			<option value={true}>Käytössä</option>
    		</Form.Select>
		</Form.Group>
		</Row>
		</>
	);
}

export function SurveyModal() {
	
	const editsurvey = useSelector((state) => state.dashboard.editsurvey)
	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const companyId = useSelector((state) => state.dashboard.companyId)
	
	const [ state, reactDispatch ] = useReducer(surveyReducer, initialState({}));

	useEffect(() => {
		if(editsurvey != null) {
			reactDispatch({type: 'state', payload: editsurvey })
		}
	}, [editsurvey])

	
	const onCancel = () => {
		dispatch({ type: 'dashboard/editsurvey', payload: null })
	}
	
	const onSave = () => {
		const params = {
			...state,
			companyId: companyId,
			deadLineDate: state.deadLineDate.toISOString().split("T")[0]
		}
		dispatch(postSurvey(token, companyId, params))
	}
	
	const title = (editsurvey == null || editsurvey.surveyId == null) ? 'Create survey' : 'Edit survey';
	
	return (
	<Modal size="xl" fullscreen='lg-down' show={editsurvey!==null} backdrop="static">
	<Modal.Header>{title}</Modal.Header>
	<Modal.Body className="bg-light">
		{ editsurvey !== null && <SurveyModalBody state={state} dispatch={reactDispatch} /> }
	</Modal.Body>
	<Modal.Footer>
		<Button variant="secondary" onClick={onCancel} >Cancel</Button>
		<Button onClick={onSave} >OK</Button>
	</Modal.Footer>
	</Modal>
	);
}



