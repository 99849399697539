import { createSlice } from '@reduxjs/toolkit'

export const questionMgmtSlice = createSlice({
	name: 'questionmgmt',
	initialState: {
		questions: null,
		languages: [],
		language: null,
		variants: [],
		variant: null,
		edititem: null,
	},
	reducers: {
		waitQuestions: (state, action) => {
			state.questions = null
			state.languages = null
			state.language = null
			state.variants = null
			state.variant = null
			state.edititem = null
		},
		questions: (state, action) => {
			state.questions = action.payload.questions
			state.languages = action.payload.languages
			state.language = action.payload.language
			state.variants = action.payload.variants
			state.variant = action.payload.variant
			state.edititem = null
		},
		edititem: (state, action) => {
			state.edititem = action.payload
		}
	}
})

export const { waitQuestions, questions, edititem } = questionMgmtSlice.actions;

export default questionMgmtSlice.reducer
